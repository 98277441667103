import { Component, Input, OnInit } from "@angular/core";
import { IWorkerDocument } from "@mydrake/domain/read/models/documents";
import { environment } from "@mydrake/environments/environment";
import { DMS } from "@shared/services";
import { DocumentsService } from "../../documents.service";

@Component({
    selector: 'document-detail',
    templateUrl: './document-detail.component.html',
    styleUrls: ['./document-detail.component.scss']
})
export class DocumentDetail implements OnInit {

    @Input() public workerDocument: IWorkerDocument;

    public olderDocuments: Array<IWorkerDocument> = [];
    public loading: boolean = true;

    constructor(private api: DocumentsService, private dms: DMS){}

    ngOnInit(): void {
        this.api.getOlderDocumentsByQualification(this.workerDocument.groupId).subscribe(documents => {
            this.olderDocuments = documents;
            this.loading = false;
        });
    }
}
